import React, {useEffect, useState} from 'react'
import ReactEcharts from 'echarts-for-react'
import echarts from 'echarts'
import style from './style.less'
import commonStyle from '../style.less'
import request from 'src/utils/request'
import {pxToRem} from "../../../utils"

const Settlement = (refresh) => {
  const [state, setState] = useState({})
  const getData = async () => {
    const resp = await request('/api/operationManagement/data/kanban/settlementStatistics')
    if (resp.success) {
      setState(resp.queryResult.entity)
    }
  }
  useEffect(() => {
    getData()
  }, [refresh])

  const option = {
    grid: {
      containLabel: true,
      left: pxToRem(10),
      right: pxToRem(10),
    },
    title: {
      text: `总结算金额`,
      textStyle: {
        color: '#fff',
        fontSize: pxToRem(12)<12?12:pxToRem(12),
      },
      subtext: `￥${state.totalAmount}`,
      subtextStyle: {
        color: '#fff',
        fontSize: pxToRem(12)<12?12:pxToRem(12),
      },
      padding: [-pxToRem(15), pxToRem(30)],
      left: 'center',
      top: '50%'
    },
    series: [{
      type: 'pie',
      radius: ['48%', '60%'],
      avoidLabelOverlap: false,
      hoverAnimation: false,
      label: {
        formatter: [
          '{gg|{b}}',
          '{c}%'
        ].join('\n'),
        rich: {
          gg: {
            padding: [pxToRem(5), 0, 0, 0]
          },
        }
      },
      data: [
        {value: state.settledPercentage, name: `已结算`, itemStyle: {color: '#F9C737'}},
        {value: state.unSettledPercentage, name: `待结算`, itemStyle: {color: '#52C2FE'}}
      ]
    }]
  };
  return (
    <div className={`${style.content} ${commonStyle.content}`}>
      <div className={commonStyle.title}>
        结算统计
      </div>
      <ReactEcharts
        className={commonStyle.chart}
        option={option}
        notMerge={true}
        lazyUpdate={true}
      />
    </div>
  )
}

export default Settlement
