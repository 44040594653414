import React, {useEffect, useState} from 'react'
import ReactEcharts from 'echarts-for-react'
import echarts from 'echarts'
import style from './style.less'
import commonStyle from '../style.less'

import request from 'src/utils/request'
import {pxToRem} from "../../../utils"
const Order = (refresh) => {
  const [state, setState] = useState({})
  const getData = () => {
    request('/api/operationManagement/data/kanban/waybillStatistics').then(res => {
      if (res.success) {
        setState(res.queryResult.entity.orderMonthCount)
      }
    })
  }
  useEffect(() => {
    getData()
  }, [refresh])

  const option = {
    grid: {
      containLabel: true,
      left: pxToRem(47),
      right: pxToRem(47),
      bottom: pxToRem(30),
      top: pxToRem(40)
    },
    xAxis: {
      type: 'category',
      data: Object.keys(state).map(v => `${v.slice(5)}月`),
      axisLine: {
        show: false,
        lineStyle: {
          color: '#fff'
        }
      },
      axisTick: {
        show: false
      },
    },
    yAxis: {
      type: 'value',
      axisLine: {
        show: false,
        lineStyle: {
          color: '#fff'
        }
      },
      axisTick: {
        show: false
      },
      splitLine: {
        lineStyle: {
          color: '#08296E'
        }
      }
    },
    series: [{
      data: Object.values(state),
      type: 'bar',
      barWidth: pxToRem(20),
      label: {
        normal: {
          position: 'top',
          show: true,
          color: "#fff"
        }
      },
      itemStyle: {
        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
          {offset: 0, color: '#59BDFF'},
          {offset: 1, color: '#02F5F8'}
        ])
      }
    }]
  };
  return (
    <div className={`${style.content} ${commonStyle.content}`}>
      <div className={commonStyle.title}>
        运单统计
        <span className={commonStyle.unit}> (单位：单)</span>
      </div>
      <ReactEcharts
        className={commonStyle.chart}
        option={option}
        notMerge={true}
        lazyUpdate={true}
      />
    </div>
  )
}

export default Order
