import React, {useEffect, useState} from 'react'
import style from "./index.less"
import logo from "@/static/images/logo.png"
import line from "@/static/images/line.png"
import moment from "moment";

const Header = () => {
  const [time, setTime] = useState(moment().format('YYYY年MM月DD日 HH:mm:ss'))
  useEffect(() => {

    const timer = setInterval(() => {
      setTime(moment().format('YYYY年MM月DD日 HH:mm:ss'))
    }, 1000)
    return () => {
      clearInterval(timer)
    }
  }, [])
  return (
    <div className={style.container}>
      <div className={style.title}>
        <img src={logo} alt=""/>
        <img src={line} alt=""/>
        <span>惠捷智运数据可视化看板</span>
      </div>
      <div className={style.time}>{time}</div>
    </div>
  )
}

export default Header
