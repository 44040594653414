import React, { useEffect, useState } from 'react'
import ReactEcharts from 'echarts-for-react'
import echarts from 'echarts'
import style from './style.less'

import request from 'src/utils/request'
import {pxToRem} from "../../../utils"
import commonStyle from "../style.less";
const CapacityStatistics = (refresh) => {
  const [state, setState] = useState([])
  const getData = () => {
    request('/api/operationManagement/data/kanban/capacityStatistics').then(res => {
      if (res.success) {
        const monthCount = Object.keys(res.queryResult.entity.carMonthCount)
        const carMonthCountValue = Object.values(res.queryResult.entity.carMonthCount)
        const driverMonthCountValue = Object.values(res.queryResult.entity.driverMonthCount)
        const data = []
        monthCount.forEach((v, i) => {
          data.push([`${v.slice(5)}月`, carMonthCountValue[i], driverMonthCountValue[i]])
        })
        setState(data)
      }
    })
  }
  useEffect(() => {
    getData()
  }, [refresh])

  const option = {
    grid: {
      containLabel: true,
      left: pxToRem(47),
      right: pxToRem(47),
      bottom: pxToRem(30),
      top: pxToRem(40)
    },
    legend: {
      textStyle: {
        color: "#fff"
      },
      itemWidth: 12,
      itemHeight: 8,
      top: pxToRem(10),
      right: pxToRem(20)
    },
    // tooltip: {
    //   trigger: "axis",
    //   axisPointer: {
    //     type: "shadow"
    //   }
    // },
    xAxis: {
      type: 'category',
      axisLine: {
        show: false,
        lineStyle: {
          color: '#fff'
        }
      },
      axisTick: {
        show: false
      },
    },
    yAxis: {
      type: 'value',
      axisLine: {
        show: false,
        lineStyle: {
          color: '#fff'
        }
      },
      axisTick: {
        show: false
      },
      splitLine: {
        lineStyle: {
          color: '#08296E'
        }
      }
    },
    dataset: {
      source: [
          ['product', '车辆(辆)', '司机(名)'],
          ...state
      ]
    },
    series: [
      {
        type: 'bar',
        barWidth: pxToRem(12),
        itemStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            { offset: 1, color: '#59BDFF' },
            { offset: 0, color: '#02F5F8' }
          ])
        }
      },
      {
        type: 'bar',
        barWidth: pxToRem(12),
        itemStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            { offset: 1, color: '#F6AC2E' },
            { offset: 0, color: '#FFE11D' }
          ])
        }
      }
    ]
  };
  return (
    <div className={`${style.content} ${commonStyle.content}`}>
      <div className={commonStyle.title}>
        运力统计
      </div>
      <ReactEcharts
        className={commonStyle.chart}
        option={option}
        notMerge={true}
        lazyUpdate={true}
      />
    </div>
  )
}

export default CapacityStatistics
