import React, {useState, useEffect} from 'react'
import ReactEcharts from 'echarts-for-react'
import china from 'echarts/map/json/china.json'
import anhui from 'echarts/map/json/province/anhui.json'
import aomen from 'echarts/map/json/province/aomen.json'
import beijing from 'echarts/map/json/province/beijing.json'
import chongqing from 'echarts/map/json/province/chongqing.json'
import fujian from 'echarts/map/json/province/fujian.json'
import gansu from 'echarts/map/json/province/gansu.json'
import guangdong from 'echarts/map/json/province/guangdong.json'
import guangxi from 'echarts/map/json/province/guangxi.json'
import guizhou from 'echarts/map/json/province/guizhou.json'
import hainan from 'echarts/map/json/province/hainan.json'
import hebei from 'echarts/map/json/province/hebei.json'
import heilongjiang from 'echarts/map/json/province/heilongjiang.json'
import henan from 'echarts/map/json/province/henan.json'
import hubei from 'echarts/map/json/province/hubei.json'
import hunan from 'echarts/map/json/province/hunan.json'
import jiangsu from 'echarts/map/json/province/jiangsu.json'
import jiangxi from 'echarts/map/json/province/jiangxi.json'
import jilin from 'echarts/map/json/province/jilin.json'
import liaoning from 'echarts/map/json/province/liaoning.json'
import neimenggu from 'echarts/map/json/province/neimenggu.json'
import ningxia from 'echarts/map/json/province/ningxia.json'
import qinghai from 'echarts/map/json/province/qinghai.json'
import shandong from 'echarts/map/json/province/shandong.json'
import shanghai from 'echarts/map/json/province/shanghai.json'
import shanxi from 'echarts/map/json/province/shanxi.json'
import shanxi1 from 'echarts/map/json/province/shanxi1.json'
import sichuan from 'echarts/map/json/province/sichuan.json'
import taiwan from 'echarts/map/json/province/taiwan.json'
import tianjin from 'echarts/map/json/province/tianjin.json'
import xianggang from 'echarts/map/json/province/xianggang.json'
import xinjiang from 'echarts/map/json/province/xinjiang.json'
import xizang from 'echarts/map/json/province/xizang.json'
import yunnan from 'echarts/map/json/province/yunnan.json'
import zhejiang from 'echarts/map/json/province/zhejiang.json'

import style from "./index.less"
import {pxToRem} from "../../../utils"
import echarts from "echarts"
import request from "../../../utils/request";
import seeChina from "@/static/images/seeChina.png"

const map = {
  'china': china,
  '安徽': anhui,
  '澳门': aomen,
  '北京': beijing,
  '重庆': chongqing,
  '福建': fujian,
  '甘肃': gansu,
  '广东': guangdong,
  '广西': guangxi,
  '贵州': guizhou,
  '海南': hainan,
  '河北': hebei,
  '黑龙江': heilongjiang,
  '河南': henan,
  '湖北': hubei,
  '湖南': hunan,
  '江苏': jiangsu,
  '江西': jiangxi,
  '吉林': jilin,
  '辽宁': liaoning,
  '内蒙古': neimenggu,
  '宁夏': ningxia,
  '青海': qinghai,
  '山东': shandong,
  '上海': shanghai,
  '山西': shanxi,
  '陕西': shanxi1,
  '四川': sichuan,
  '台湾': taiwan,
  '天津': tianjin,
  '香港': xianggang,
  '新疆': xinjiang,
  '西藏': xizang,
  '云南': yunnan,
  '浙江': zhejiang,
}
let geoCoordMap = {}, code, title;
const Map = (refresh) => {
  const [current, setCurrent] = useState("china")
  const [mapData1, setMapData1] = useState([])
  const [mapData2, setMapData2] = useState([])
  const [mapList, setMapList] = useState([])
  const [basicData, setBasicData] = useState({
    carCount: 0,
    driverCount: 0,
    oneHourAddCapitalFlow: "0.00",
    oneHourAddCarCount: 0,
    oneHourAddDriverCount: 0,
    oneHourAddTotalWeight: "0.00",
    totalCapitalFlow: "0.00",
    totalWeight: "0.00",
  })

  const setGeoCoordMap = () => {
    geoCoordMap = {}
    const mapList = []
    const list = map[current].features
    for (let i = 0; i < list.length; i++) {
      geoCoordMap[list[i].id] = {
        name: list[i].properties.name,
        position: list[i].properties.cp
      }
      mapList.push({
        name: list[i].properties.name,
        code: list[i].id
      })
    }
    setMapList(mapList)
  }
  const getData = (areaLevel) => {
    let url
    url = `/api/operationManagement/data/kanban/mapStatisticsDetails?areaLevel=${areaLevel}`
    if (areaLevel === 2) {
      url = `${url}&areaCode=${code}`
    }

    request(url).then(res => {
      if (res.success) {
        if (areaLevel === 1) {
          const mapList1 = res.queryResult.entity.mapStatisticsDetailsVOList
          const mapList2 = res.queryResult.entity.secondStatisticsList
          setMapData1(mapList1)
          setMapData2(mapList2)
        } else {
          const list = res.queryResult.entity.secondStatisticsList
          setMapList(map[current].features.map(item => {
            let dispatchWaybillNumber = ''
            let dispatchWeight = ''
            let deliveryWaybillNumber = ''
            let deliveryWeight = ''
            for (let i in list) {
              if (!list.hasOwnProperty(i)) {
                continue
              }
              if (item.id === list[i].loadingCityCode) {
                dispatchWaybillNumber = list[i].dispatchWaybillNumber
                dispatchWeight = list[i].dispatchWeight
              }
              if (item.id === list[i].unloadingCityCode) {
                deliveryWaybillNumber = list[i].deliveryWaybillNumber
                deliveryWeight = list[i].deliveryWeight
              }
              if (dispatchWaybillNumber && deliveryWaybillNumber) {
                break
              }
            }
            return {
              name: item.properties.name,
              code: item.id,
              dispatchWaybillNumber,
              dispatchWeight,
              deliveryWaybillNumber,
              deliveryWeight
            }
          }))
        }
      }
    })
  }
  const getNumData = () => {
    request('/api/operationManagement/data/kanban/basicStatistics').then(res => {
      if (res.success) {
        setBasicData(res.queryResult.entity)
      }
    })
  }
  useEffect(() => {
    getNumData()
    if (refresh !== 1) {
      setGeoCoordMap()
      getData(current === 'china' ? 1 : 2)
    }
    return () => {
    }
  }, [refresh])
  useEffect(() => {
    setGeoCoordMap()
    getData(current === 'china' ? 1 : 2)
  }, [current])

  echarts.registerMap(current, map[current]);
  let colors = ["#FFDB5C", "#FFDB5C"];
  // let colors = ["#f57b15", "#FFDB5C"];

  let convertData = function (data) {
    if (JSON.stringify(geoCoordMap) === '{}') {
      return
    }
    if (current !== 'china') {
      return []
    }
    let res = [];
    for (let i = 0; i < data.length; i++) {
      if (!geoCoordMap[data[i].loadingProvinceCode]) {
        continue
      }
      let geoCoord = geoCoordMap[data[i].loadingProvinceCode].position;
      if (geoCoord) {
        const color = Number(data[i].loadingProvinceCode) < Number(data[i].unloadingProvinceCode) ? colors[1] : colors[0]
        res.push({
          // name: data[i].name,
          code: data[i].loadingProvinceCode,
          value: geoCoord.concat(data[i].waybillNum),
          itemStyle: {
            normal: {
              color,
              shadowBlur: 10,
              shadowColor: color
            }
          },
        });
      }
    }
    return res;
  };
  let convertToLineData = function (data) {
    if (JSON.stringify(geoCoordMap) === '{}') {
      return
    }
    if (current !== 'china') {
      return []
    }
    let res = [];
    for (let i = 0; i < data.length; i++) {
      if (!geoCoordMap[data[i].loadingProvinceCode]) {
        continue
      }
      let dataItem = data[i];
      let toCoord = geoCoordMap[dataItem.unloadingProvinceCode].position;
      let fromCoord = geoCoordMap[dataItem.loadingProvinceCode].position; //
      if (fromCoord && toCoord && fromCoord !== toCoord) {
        const obj = [{
          coord: fromCoord,
          value: Number(dataItem.waybillNum),
          code: dataItem.loadingProvinceCode,
          lineStyle: {
            color: colors[1],
            width: 1, //尾迹线条宽度
            opacity: 0.5, //尾迹线条透明度
            curveness: 0.3 //尾迹线条曲直度
          },
        }, {
          coord: toCoord,
        }]
        if (Number(dataItem.waybillNum) >= 3) {
          obj[0].lineStyle.curveness = 0.3
          res.push([{
            ...obj[0],
            lineStyle: {
              ...obj[0].lineStyle,
              curveness: 0.25
            }
          }, {...obj[1]}])
          res.push([{
            ...obj[0],
            lineStyle: {
              ...obj[0].lineStyle,
              curveness: 0.3
            }
          }, {...obj[1]}])
          res.push([{
            ...obj[0],
            lineStyle: {
              ...obj[0].lineStyle,
              curveness: 0.35
            }
          }, {...obj[1]}])
        } else if (Number(dataItem.waybillNum) === 2) {
          res.push([{
            ...obj[0],
            lineStyle: {
              ...obj[0].lineStyle,
              curveness: 0.25
            }
          }, {...obj[1]}])
          res.push([{
            ...obj[0],
            lineStyle: {
              ...obj[0].lineStyle,
              curveness: 0.35
            }
          }, {...obj[1]}])
        } else {
          res.push([{
            ...obj[0],
            lineStyle: {
              ...obj[0].lineStyle,
              curveness: 0.3
            }
          }, {...obj[1]}])
        }
      }
    }
    return res;
  };

  let option = {
    animation: true,
    animationDuration: 1000,
    animationEasing: 'cubicInOut',
    animationDurationUpdate: 1000,
    animationEasingUpdate: 'cubicInOut',
    title: {
      show: current !== 'china',
      text: title,
      left: pxToRem(36),
      top: 0,
      textStyle: {
        color: '#04f4f8',
        fontSize: 30,
      }
    },
    geo: {
      show: true,
      map: current,
      roam: false, //是否开启鼠标缩放和平移漫游。默认不开启。如果只想要开启缩放或者平移，可以设置成 'scale' 或者 'move'。设置成 true 为都开启
      zoom: 1.1,
      label: {
        normal: {
          show: true,
          color: "#33b3bb"
        },
        emphasis: {
          show: false,
          color: "#FFFFFF"
        }
      },
      itemStyle: {
        normal: {
          borderColor: 'rgba(147, 235, 248, 1)',
          borderWidth: 1,
          areaColor: {
            type: 'radial',
            x: 0.5,
            y: 0.5,
            r: 0.8,
            colorStops: [{
              offset: 0,
              color: 'rgba(1, 77, 100, .5)' // 0% 处的颜色
            }, {
              offset: 1,
              color: 'rgba(1, 70, 91, .8)' // 100% 处的颜色
            }],
            globalCoord: false // 缺省为 false
          },
          shadowColor: 'rgba(128, 217, 248, 1)',
          shadowOffsetX: -2,
          shadowOffsetY: 4,
          shadowBlur: 10
        },
        emphasis: {
          areaColor: '#389BB7',
          borderWidth: 0
        }
      },
      // regions: area.map(item => {
      //   return {
      //     name: item.name,
      //     itemStyle: {
      //       areaColor: {
      //         type: 'radial',
      //         x: 0.5,
      //         y: 0.5,
      //         r: 0.8,
      //         colorStops: [{
      //           offset: 0,
      //           color: 'rgba(3, 211, 230, .4)' // 0% 处的颜色
      //         }, {
      //           offset: 1,
      //           color: 'rgba(9, 149, 167, .6)' // 100% 处的颜色
      //         }],
      //         globalCoord: false // 缺省为 false
      //       },
      //     },
      //     label: {
      //       color: '#34b7bf',//字体颜色
      //       show: true
      //     }
      //   }
      // })
    },
    tooltip: {
      show: true
    },
    series: [
      //地图
      {
        type: 'map',
        map: current,
        zlevel: 10,
        geoIndex: 0,
        aspectScale: 0.75, //长宽比
        showLegendSymbol: false, // 存在legend时显示
        label: {
          normal: {
            show: true
          },
          emphasis: {
            show: false,
            textStyle: {
              color: '#fff'
            }
          }
        },
        tooltip: {
          show: current !== 'china',
          formatter: ({data}) => {
            if (current === 'china') {
              return ''
            }
            const {dispatchWaybillNumber, dispatchWeight, deliveryWaybillNumber, deliveryWeight} = data
            return `出货重量${dispatchWeight || '0.00'}吨，出货运单数${dispatchWaybillNumber || '0'}单 <br/> 收货重量${deliveryWeight || '0.00'}吨，收货运单数${deliveryWaybillNumber || '0'}单`
          },
        },
        roam: false,
        animation: false,
        data: mapList
      },
      //地图点的动画效果
      {
        type: 'effectScatter',
        coordinateSystem: 'geo',
        data: convertData([...mapData1, ...mapData2].sort(function (a, b) {
          return Number(b.waybillNum) - Number(a.waybillNum);
        }).slice(0, 20)),
        symbolSize: function (val) {
          return 5;
        },
        tooltip: {
          show: false
        },
        showEffectOn: 'render',
        rippleEffect: {
          brushType: 'stroke'
        },
        hoverAnimation: true,
        label: {
          normal: {
            formatter: '{b}',
            position: 'right',
            show: false
          }
        },
        zlevel: 3
      },
      //地图线的动画效果
      {
        type: 'lines',
        zlevel: 2,
        effect: {
          show: true,
          period: 2, //箭头指向速度，值越小速度越快
          trailLength: 0.02, //特效尾迹长度[0,1]值越大，尾迹越长重
          symbol: 'arrow', //箭头图标
          symbolSize: 5, //图标大小
        },
        label: {
          normal: {
            show: false
          }
        },
        // lineStyle: {
        //   normal: {
        //     color: colors[1],
        //     width: 1, //尾迹线条宽度
        //     opacity: 0.5, //尾迹线条透明度
        //     curveness: 0.3 //尾迹线条曲直度
        //   }
        // },
        data: convertToLineData(mapData1)
      },
      {
        type: 'lines',
        zlevel: 2,
        effect: {
          show: true,
          period: 2, //箭头指向速度，值越小速度越快
          trailLength: 0.02, //特效尾迹长度[0,1]值越大，尾迹越长重
          symbol: 'arrow', //箭头图标
          symbolSize: 5, //图标大小
        },
        label: {
          normal: {
            show: false
          }
        },
        lineStyle: {
          normal: {
            color: colors[0],
            width: 1, //尾迹线条宽度
            opacity: 0.5, //尾迹线条透明度
            curveness: .3 //尾迹线条曲直度
          }
        },
        data: convertToLineData(mapData2)
      },
    ]
  };
  const EventsDict = {
    click: (e) => {
      if(e.data.coord){
        return
      }
      if (e.data && e.data.code) {
        if (current === 'china') {
          if (['新疆', '内蒙古', '西藏'].includes(geoCoordMap[e.data.code].name)) {
            title = geoCoordMap[e.data.code].name + '自治区'
          }else if(geoCoordMap[e.data.code].name==='宁夏'){
            title = '宁夏回族自治区'
          }else if(geoCoordMap[e.data.code].name==='广西'){
            title = '广西壮族自治区'
          }else if(['北京', '天津', '上海','重庆'].includes(geoCoordMap[e.data.code].name)){
            title = geoCoordMap[e.data.code].name + '市'
          }else if(['香港', '澳门'].includes(geoCoordMap[e.data.code].name)){
            title = geoCoordMap[e.data.code].name + '特别行政区'
          }else {
            title = geoCoordMap[e.data.code].name + '省'
          }
          code = e.data.code
          setCurrent(geoCoordMap[e.data.code].name)
        }
      }
    },
  }

  const back = () => {
    setCurrent('china')
  }

  return (
    <div className={style.container}>
      <div className={style.table}>
        <table>
          <thead>
          <tr>
            <th>
              资金流水总金额(万元)
            </th>
            <th>
              运输重量(万吨)
            </th>
            <th>
              司机数(名)
            </th>
            <th>
              车辆数(辆)
            </th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>
              <span>{basicData.totalCapitalFlow}</span>
              {
                Number(basicData.oneHourAddCapitalFlow) !== 0 &&
                <span className={Number(basicData.oneHourAddCapitalFlow) > 0 ? style.add : style.reduce}>
                  {Number(basicData.oneHourAddCapitalFlow) > 0 ? "+" : ""}
                  {basicData.oneHourAddCapitalFlow}
                </span>
              }
            </td>
            <td>
              <span>{basicData.totalWeight}</span>
              {
                Number(basicData.oneHourAddTotalWeight) !== 0 &&
                <span className={Number(basicData.oneHourAddTotalWeight) > 0 ? style.add : style.reduce}>
                  {Number(basicData.oneHourAddTotalWeight) > 0 ? "+" : ""}
                  {basicData.oneHourAddTotalWeight}
                </span>
              }
            </td>
            <td>
              <span>{basicData.driverCount}</span>
              {
                Number(basicData.oneHourAddDriverCount) !== 0 &&
                <span className={Number(basicData.oneHourAddDriverCount) > 0 ? style.add : style.reduce}>
                  {Number(basicData.oneHourAddDriverCount) > 0 ? "+" : ""}
                  {basicData.oneHourAddDriverCount}
                </span>
              }
            </td>
            <td>
              <span>{basicData.carCount}</span>
              {
                Number(basicData.oneHourAddCarCount) !== 0 &&
                <span className={Number(basicData.oneHourAddCarCount) > 0 ? style.add : style.reduce}>
                  {Number(basicData.oneHourAddCarCount) > 0 ? "+" : ""}
                  {basicData.oneHourAddCarCount}
                </span>
              }
            </td>
          </tr>
          </tbody>
        </table>
        <div>

        </div>
      </div>
      <ReactEcharts
        className='m-b-35-n m-t-35-n'
        style={{width: '100%', height: '100%'}}
        option={option}
        onEvents={EventsDict}
        notMerge={true}
        lazyUpdate={true}
      />
      {/*<div className={style.legend}>*/}
      {/*  <div><span/><span>覆盖区域</span></div>*/}
      {/*  <div><span/><span>非覆盖区域</span></div>*/}
      {/*</div>*/}
      {
        current !== 'china' &&
        <div className={style.back} onClick={back}>
          <img src={seeChina} alt=""/>
          <div>查看全国</div>
        </div>
      }
    </div>
  )
}

export default Map
