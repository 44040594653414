import React, {useEffect, useState} from 'react'
import ReactEcharts from 'echarts-for-react'
import echarts from 'echarts'
import style from './style.less'

import request from 'src/utils/request'
import commonStyle from "../style.less";
import {pxToRem} from "../../../utils";

const TransportWeightStatistics = (refresh) => {
  const [state, setState] = useState({})
  const getData = () => {
    request('/api/operationManagement/data/kanban/goodsClassifyStatistics').then(res => {
      if (res.success) {
        setState(res.queryResult.entity.goodsClassifyStatistics)
      }
    })
  }
  useEffect(() => {
    getData()
  }, [refresh])

  const colors = ['#DD025C', '#F9C737', '#05C498']

  const data = Object.values(state).map((v, i) => ({
    title: {
      text: `${[v.goodsTypeName]}`,
      textStyle: {
        color: '#fff',
        fontSize: 12,
        fontWeight:'normal'
      },
      subtext: `${v.totalWeight}万吨`,
      subtextStyle: {
        color: '#fff',
        fontSize: 12
      },
      // itemGap: pxToRem(34),
      left: 'center',
      top: '60%',
    },
    grid: {
      top: 0,
      bottom: 0,
      left: 0,
      right: 0
    },
    angleAxis: {
      max: 100,
      clockwise: true, // 逆时针
      show: false
    },
    radiusAxis: {
      type: 'category',
      show: true,
      axisLabel: {
        show: false,
      },
      axisLine: {
        show: false,

      },
      axisTick: {
        show: false
      },
    },
    polar: {
      center: ['50%', '40%'],
      radius: '100%' //图形大小
    },
    series: [{
      type: 'bar',
      data: [v.percentage],
      showBackground: true,
      backgroundStyle: {
        color: '#3B4151',
      },
      itemStyle: {
        color: colors[i]
      },
      coordinateSystem: 'polar',
      roundCap: true,
      barWidth: 5,
    }]
  }))

  return (
    <div className={`${style.content} ${commonStyle.content}`}>
      <div className={commonStyle.title}>
        货物分析统计
      </div>
      <div className={style.charts}>
        {data.map((_, i) =>
          <ReactEcharts
            key={i}
            className={style.chart}
            option={data[i]}
            notMerge={true}
            lazyUpdate={true}
          />
        )}
        <div className={style.weight}>
          {Object.values(state).map((v, i) => (
            <div key={i}>{v.percentage}%</div>
          ))}
        </div>
      </div>

      {/*<div className={style.label}>*/}
      {/*  {Object.values(state).map((v, i) => (*/}
      {/*    <div key={i}><div className={style.name}><span className={style.pie} style={{background: colors[i]}}></span>{v.goodsTypeName}</div> <div className={style.totalWeight}>{v.totalWeight}万吨</div> <div>昨日新增{v.lastAddWeight}万吨</div></div>*/}
      {/*  ))}*/}
      {/*</div>*/}
    </div>
  )
}

export default TransportWeightStatistics
