import React, {useEffect, useState} from 'react'
import Header from '@/components/header'
import Map from '@/components/map'
import Order from '@/components/order'
import Settlement from '@/components/settlement'
import CapacityStatistics from '@/components/capacityStatistics'
import CapitalFlowStatistics from '@/components/capitalFlowStatistics'
import TransportWeightStatistics from '@/components/transportWeightStatistics'
import GoodsClassifyStatistics from '@/components/goodsClassifyStatistics'
import moment from "moment";
import style from "./index.less"
import request from "../../utils/request";

let timer = null, timer2 = null
const Dashboard = () => {
  const [refresh, setRefresh] = useState(1);
  useEffect(() => {
    request('/api/operationManagement/data/kanban/getServiceDate').then(res => {
      if (res.success) {
        const time = moment(moment(res.queryResult.entity).format('YYYY-MM-DD HH:00:00')).add(61, "minute")
        const interval = time.diff(moment(res.queryResult.entity), 'second')
        timer2 = setTimeout(() => {
          setRefresh(n => n + 1)
          if (timer) {
            clearInterval(timer)
          }
          timer = setInterval(() => {
            setRefresh(n => n + 1)
          }, 60 * 60 * 1000)
        }, interval * 1000)
      }
    })
    return () => {
      if (timer) {
        clearInterval(timer)
      }
      if (timer2) {
        clearTimeout(timer2)
      }
    }
  }, [])
  return (
    <div className={style.container}>
      <Header/>
      <Order refresh={refresh}/>
      <Settlement refresh={refresh}/>
      <CapacityStatistics refresh={refresh}/>
      <CapitalFlowStatistics refresh={refresh}/>
      <TransportWeightStatistics refresh={refresh}/>
      <GoodsClassifyStatistics refresh={refresh}/>
      <Map/>
    </div>
  )
}

export default Dashboard
