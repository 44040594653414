import style from './App.less'
import Dashboard from './pages/dashboard'

import {
  BrowserRouter as Router,
  Route,
} from "react-router-dom"

function App() {
  return (
    <div className={style.App}>
      <Router>
        <Route path="/">
          <Dashboard />
        </Route>
      </Router>
    </div>
  );
}

export default App;
