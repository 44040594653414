import React, { useEffect, useState } from 'react'
import ReactEcharts from 'echarts-for-react'
import echarts from 'echarts'
import style from './style.less'

import request from 'src/utils/request'
import commonStyle from "../style.less";
import {pxToRem} from "../../../utils"
const TransportWeightStatistics = (refresh) => {
  const [state, setState] = useState({})
  const getData = () => {
    request('/api/operationManagement/data/kanban/transportWeightStatistics').then(res => {
      if (res.success) {
        setState(res.queryResult.entity.transportWeightMonthCount)
      }
    })
  }
  useEffect(() => {
    getData()
  }, [refresh])

  const option = {
    // tooltip: {
    //   trigger: "axis",
    //   axisPointer: {
    //     type: "shadow"
    //   }
    // },
    grid: {
      containLabel: true,
      left: pxToRem(47),
      right: pxToRem(47),
      bottom: pxToRem(17),
      top: pxToRem(27)
    },
    xAxis: {
      type: 'value',
      axisLine: {
        show: false,
        lineStyle: {
          color: '#fff'
        }
      },
      axisTick: {
        show: false
      },
      splitLine: {
        lineStyle: {
          color: '#08296E'
        }
      }
    },
    yAxis: {
      type: 'category',
      data: Object.keys(state).map(v => `${v.slice(5)}月`),
      axisLine: {
        show: false,
        lineStyle: {
          color: '#fff'
        }
      },
      axisTick: {
        show: false
      },
      splitLine: {
        lineStyle: {
          color: '#08296E'
        }
      }
    },
    series: [{
      data: Object.values(state),
      type: 'bar',
      barWidth: pxToRem(12),
      itemStyle: {
        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
          { offset: 0, color: '#F6AC2E' },
          { offset: 1, color: '#FFE11D' }
        ])
      },
      label: {
        normal: {
          position: 'right',
          show: true,
          color: "#fff"
        }
      },
    }]
  };
  return (
    <div className={`${style.content} ${commonStyle.content}`}>
      <div className={commonStyle.title}>
        运输重量统计
        <span className={commonStyle.unit}> (单位：万吨)</span>
      </div>
      <ReactEcharts
        className={commonStyle.chart}
        option={option}
        notMerge={true}
        lazyUpdate={true}
      />
    </div>
  )
}

export default TransportWeightStatistics
